import './App.css';
import { AppBar, Box, Button, CircularProgress, createTheme, styled, ThemeProvider, Toolbar, Typography } from '@mui/material';
import propayaLogo from './images/Propaya_Logo.png';
import colors from './lib/colors';
import { useCallback, useEffect, useState } from 'react';
import { AnalysisResult } from './lib/interfaces';
import { AccountCircle, CloudUploadOutlined } from '@mui/icons-material';
import { useDropzone } from 'react-dropzone';
import { BlobServiceClient, BlockBlobClient } from '@azure/storage-blob';
import { AnalysisDataDisplay } from './components/AnalysisDataDisplay';

// const analytics = getAnalytics(firebaseApp);

enum View {
  NotUploaded,
  Uploading,
  Processing,
  Preview
}

export const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

function App() {
  const theme = createTheme({
    typography: {
      button: {
        textTransform: "none"
      },
      fontFamily: "Roboto, Arial",
      allVariants: {
        color: colors.greyDarker
      }
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: 100,
            // "&:hover": {
            //   backgroundColor: colors.violetLighter,
            // },
          }
        },
        defaultProps: {
          disableElevation: true
        }
      },
    },
    palette: {
      primary: {
        main: colors.violetDark,
      },
      secondary: {
        main: colors.violetLightest,
      },
      error: {
        main: colors.red,
        contrastText: "white"
      }
    }
  });
  const [selectedFile, setSelectedFile] = useState<File>(new File([], "No file selected"));
  const [data, setData] = useState<AnalysisResult>();
  const [view, setView] = useState<View>(View.NotUploaded);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);

  const onDrop = useCallback((acceptedFiles: File[]) => {
    // if (acceptedFiles.length == 0) return setUploadErrorText("Invalid file, please upload correct file type.");
    FileChosen(acceptedFiles[0])
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1,
    accept: {
      'application/pdf': [".pdf"],
      'image/png': ['.png'],
      'image/jpeg': ['.jpeg', '.jpg'],
      "image/heic": []
    },
    // disabled: (uploadState != UploadState.NotUploaded)
  });

  async function FetchData(blobName: string) {
    const requestBody = {
      "blobName": blobName
    };
    const response = await fetch("https://leaseanalysisfunctions.azurewebsites.net/api/demo_analyze", { // update to demo analyze endpoint
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      headers: {
        "Content-Type": "application/json",
      },
      // credentials: "include",
      body: JSON.stringify(requestBody)
    });
    setData(await response.json());
  }


  async function handleFileChanged(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.files === null || event.target.files.length === 0) return;
    FileChosen(event.target.files[0])
  }

  async function UploadFile(file: File) {
    const response = await fetch(`https://leaseanalysisfunctions.azurewebsites.net/api/fetch_demo_sas?blobName=${file.name}`, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      headers: {
        "Content-Type": "application/json",
      },
    });
    const SAS = await response.json();
    const blockBlobClient = new BlockBlobClient(SAS);
    try {
      await blockBlobClient.uploadData(file);
      return blockBlobClient.name;
    }
    catch {
      return "";
    }
  }

  async function FileChosen(file: File) {
    setView(View.Uploading)
    setSelectedFile(file);
    const blobName = await UploadFile(file);
    if (blobName === "") return alert("Upload failed!");
    setView(View.Processing)
    const url = URL.createObjectURL(file);
    setPreviewUrl(url);
    // console.log(blobName);
    FetchData(blobName);
    setView(View.Preview);
  }

  return (
    <ThemeProvider theme={theme}>
      <Box height={"100vh"}>
        <Box>
          <AppBar position='sticky' >
            <Toolbar sx={{ bgcolor: "white", height: "84px" }}>
              <Box display={"flex"} flexDirection={"row"} width={"100%"}>
                <Box position={"absolute"}>
                  <Box component="img" src={propayaLogo} sx={{ height: "48px" }} />
                </Box>
                <Box width={"100%"} flex={1}>
                  <Typography variant='h4' textAlign={"center"}>Lease Analysis</Typography>
                </Box>
              </Box>
            </Toolbar>
          </AppBar>
          <Box display={"flex"} flexDirection={"row"} justifyContent={"center"} height={"calc(100vh - 84px)"} width={"100%"} bgcolor={colors.SchemesSurfaceDim}>
            {
              view === View.NotUploaded &&
              <Box
                {...getRootProps()}
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent={"center"}
                gap={theme.spacing(2)}
                margin={"auto"}
                sx={{
                  background: colors.SchemesSurfaceContainerLow,
                  padding: theme.spacing(4),
                  borderRadius: theme.spacing(4),
                  width: "50vw",
                  height: "40vh"
                }}
              >
                <CloudUploadOutlined color="primary" sx={{ width: 101, height: 101 }} />
                <Typography>Drop your file here or</Typography>
                <VisuallyHiddenInput name="fileHolder" id="fileHolder" type="file" onChange={handleFileChanged} {...getInputProps()} />
                <Button component="label" variant="contained" color="secondary">
                  Browse
                </Button>
                <Typography textAlign="center" variant="body2" color="grey">Accepted file types: .pdf, .png, .jpg, .jpeg. <br /> Maximum file size 10 MB </Typography>
              </Box>
            }
            {
              view === View.Uploading &&
              <Box margin="auto" display={"flex"} flexDirection={"column"} alignItems={"center"} bgcolor={"white"} borderRadius={theme.spacing(4)} padding={theme.spacing(16)}>
                <Typography variant='h3' marginBottom={theme.spacing(4)}>Uploading...</Typography>
                <CircularProgress />
              </Box>
            }
            {
              (view === View.Preview || view === View.Processing) &&
              <Box display={"flex"} flexDirection={"row"} height={"100%"} width={"100vw"}>
                <Box height={"100%"} flex={1}>
                  {previewUrl ? (
                    <iframe
                      src={previewUrl}
                      width="100%"
                      height="100%"
                      title="PDF Preview"
                    />
                  ) : (
                    <Typography>Loading preview...</Typography>
                  )}
                </Box>
                <Box height={"calc(100vh - 84px)"} flex={1} overflow={"auto"} >
                  {
                    view === View.Processing || data === undefined ?
                      <Box display={"flex"} flexDirection={"column"} alignItems={"center"} justifyContent={"center"} height={"100%"} >
                        <Typography variant='h3' marginBottom={theme.spacing(4)}>Processing...</Typography>
                        <CircularProgress />
                      </Box>
                      :
                      <AnalysisDataDisplay data={data} />
                  }
                </Box>
              </Box>
            }
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default App;
